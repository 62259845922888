<template>
    <div class="contain">
        <searchBar :searchInput="false"></searchBar>
        <div class="preview" v-if="!url">
            <div class="preview" ref="canvas">
                <div v-for="(item, index) in agrs" :key="index">
                    <div v-if="item.isSign" style="position: relative;">
                        <img :src="item.url" class="img-item" :data-isSign="item.isSign" alt="">
                        <img src="../../assets/imgs/signTmp.png" class="signTmp" :style="{top:top+'px',left:left+'px'}" alt="">
                    </div>
                    <img :src="item.url" class="img-item" :data-isSign="item.isSign" alt="" v-else>
                </div>
            </div>
        </div>
        <div class="preview" v-else>
            <img :src="url" alt="">
        </div>
        <div class="flex_cen mt_15">
            <el-button type="info" class="next-btn mr_20" @click="back">上一步</el-button>
            <el-button type="primary" class="next-btn" @click="next">确定上传</el-button>
        </div>
    </div>
</template>

<script>
import { s_getItem, s_clear } from "@/common/cache"
// import html2canvas from 'html2canvas'
// import axios from 'axios'
export default {
	name: 'Preview',
	data() {
		return {
			baseApi: process.env.VUE_APP_API + '/api/v2/upload/image?filename=file&type=company',
			loading: null,
			id: '',
			left: 0,
			top: 0,
			agrs: [],
			url: '',
			agrFile: ''
		}
	},
	created() {
		this.id = this.$route.params.id
		this.left = s_getItem('_drag_left')
		this.top = s_getItem('_drag_top')
		let agrs = s_getItem('_agrs')
		this.agrs = agrs
		if (!this.top) {
			this.back()
		}
	},
	beforeRouteLeave(to, from, next) {
		if (to.name !== 'Setting') {
			s_clear()
		}
		next()
	},
	methods: {
		next() {
			let urls = []
			let signIndex = 0
			this.agrs.forEach((item, index)=>{
				urls.push(item.url)
				if(item.isSign) {
					signIndex = index // 签署页下标
				}
			})
			this.$axios({
				url: '/ent/v3/reward/agreement',
				data: {
					reward_id: this.id,
					upload_img: urls,
					sign: signIndex,
					x: this.left+50,
					y: this.top+25
				},
				method: 'POST',
				shake: true,
				hideLoading: true
			}).then(ret => {
				setTimeout(() => {
					this.loading = false
				}, 900)
				if (ret.code === 200) {
					this.$message.success("上传成功")
					setTimeout(() => {
						s_clear()
						this.$router.replace(`/employment`)
					}, 1000)
				}
			})
		},
		back() {
			this.$router.go(-1)
		}
	}
}
</script>

<style lang='less' scoped>
.preview {
    width: 600px;
    margin: 0 auto;
    margin-top: 40px;
    position: relative;
}
.signTmp {
    width: 200px;
    height: 100px;
    position: absolute;
    z-index: 3;
}
.next-btn {
    width: 200px;
    height: 52px;
    font-size: 18px;
    margin-top: 30px;
}
</style>